
.exp {
    padding: 40px 80px;
    position: relative;
}

.exp h2 {

    font-size: 45px;
    font-weight: 700;
    text-align: left;
    padding-top: 40px;
    padding-bottom: 40px;

}

.timeline-items {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.timeline-items::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background-color: teal;;
    left: calc(50% - 1px);
}

.timeline-item {
    margin-bottom: 40px;
    width: 100%;
    position: relative;
}

.timeline-item:last-child {
    margin-bottom: 0;
}

.timeline-item:nth-child(odd) {
    padding-right: calc(50% + 32px);
    text-align: right;
}

.timeline-item:nth-child(even) {
    padding-left: calc(50% + 32px);
    text-align: left;
    /*my supposition*/
}

.timeline-dot {
    height: 19px;
    width: 19px;
    background-color: aqua;
    box-shadow: 0 0 25px teal,
    0 0 50px lightseagreen;
    position: absolute;
    left: calc(50% - 8px);
    border-radius: 50%;
    top: 8px;

}

.timeline-date {
    font-size: 20px;
    font-weight: 800;
    color: white;
    margin: 6px 0 15px;
}

.timeline-content {
    background-color: rgba(0, 0, 0, 0.7);
    border: 3px solid lightseagreen;
    padding: 20px 30px;
    border-radius: 2rem;
    box-shadow: 0 0 10px teal;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.timeline-content:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px aqua;

}

.timeline-content h3 {
    font-size: 30px;
    color: white;
    margin: 0 0 10px;
    font-weight: 500;
}

.timeline-content p {
    color: white;
    font-size: 1.15em;
    font-weight: 300;
    line-height: 22px;

}

.timeline-content h5 {
    font-size: 1.3em;

}

/*::-webkit-scrollbar {*/
/*    width: 15px;*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*    background-color: darkslateblue;*/
/*}*/

/*::-webkit-scrollbar-track {*/
/*    background-color: teal;*/
/*    width: 50px;*/
/*}*/


@media (max-width: 768px) {
    .exp {
        padding: 20px;
    }

    .exp h2 {
        font-size: 35px;
        text-align: left;
    }

    .timeline-items {
        display: block;
    }

    .timeline-item {
        width: 100%;
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
    }

    .timeline-item:nth-child(odd), .timeline-item:nth-child(even) {
        padding: 0;
    }

    .timeline-dot {
        display: none;

    }
    .timeline-items::before {
        display: none;
    }

    .timeline-content {
        margin-left: 45px;
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .exp h2 {
        font-size: 25px;
    }

    /*.timeline-content h3 {*/
    /*    font-size: 20px;*/
    /*}*/

    /*.timeline-content p {*/
    /*    font-size: 14px;*/
    /*}*/

    .timeline-date {
        font-size: 24px;
    }


}