.footer {
    padding: 40px 0 40px 0;
    background: linear-gradient(to bottom,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.9) 50%,
    rgba(0, 0, 0, 0.9) 100%);
}

.footer-second-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.align-items-center {
    display: flex;
    justify-content: space-around;
    align-items: center;
}


#footer-dash {
    width: 50%;
    animation: updown 3s linear infinite;
}

.footer-social-icon {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 10px;
}


.footer p {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;

}

.footer h4 {
    color: white;
    margin-top: 10px;
}

.footer button {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    margin-top: 20px;
    letter-spacing: 0.8px;
    display: inline-flex;
    align-items: center;

}

.footer button svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
}

.footer button:hover svg {
    margin-left: 25px;
}

#downloadCV-button {
    color: white;


}

#downloadCV-button:hover {
    color: lightpink;
    text-shadow: 0 0 20px hotpink,
    0 0 20px darkslateblue,
    0 0 25px white;

}


