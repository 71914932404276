.skill {
    padding: 40px 80px;
    position: relative;
}

.skill-bx {

    background: transparent;
    text-align: right;

}

.skill h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: left;
    padding-top: 40px;
    padding-bottom: 40px;

}

.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 16px 0 40px 0;
    position: relative;
    transition: color 0.3s ease, text-shadow 0.3s ease;

}


.skill p:hover {
    color: aquamarine;
    text-shadow: 0 0 2px teal,
    0 0 10px teal,
    0 0 15px teal,
    0 0 20px aquamarine;
}

.skill-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(72px, 1fr));
    gap: 32px;

    padding: 40px 104px;
}


@media (max-width: 1200px) {
    .skill-grid {
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
        padding: 40px 80px;
    }
}

@media (max-width: 768px) {
    .skill {
        padding: 20px 10px;

    }

    .skill-grid {
        grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
        padding: 20px 40px;
    }
}

@media (max-width: 480px) {
    .skill {
        padding: 20px 10px;

    }
    .skill-grid {
        /*grid-template-columns: repeat(3, 2fr);*/

        grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
        padding: 0 0;
    }
}

.item {

    position: relative;
    text-align: center;

    transition: transform 0.2s, box-shadow 0.2s, border-color 0.2s;
}

.item img {
    width: 64px;
    height: 64px;
    padding: 4px;
    transition: filter 0.2s;
}

.item:hover,
.item:focus {
    transform: scale(1.05);
    box-shadow: 0px 0px 8px 4px #80deea;
    /*background-color: rgba(245, 245, 245, 0.7);*/


}

.item:hover img,
.item:focus img {
    filter: brightness(1.2);
}

.item:active {
    transform: scale(0.95);
    background-color: rgba(128, 222, 234, 0.5);
}

.item:active img {
    filter: brightness(1.5);
}

.item::before {
    content: attr(data-title);
    position: absolute;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    padding: 0 10px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
}

.item:hover::before,
.item:focus::before,
.item:active::before {
    opacity: 1;
    visibility: visible;
}

/* Additional media query for smaller screens */
@media (max-width: 600px) {
    .skill-grid {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        gap: 10px;
    }

    .skill h2 {
        font-size: 35px;
        text-align: left;
    }

    .item img {
        width: 60px;
        height: 60px;
    }

    .item::before {
        font-size: 12px;
        padding: 3px 6px;
    }
}


.background-image-left {
    top: 28%;
    position: absolute;
    bottom: 0;
    width: 40%;
    z-index: -4;
}

.background-image-landscape {
    top: 0%;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -4;
}