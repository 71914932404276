.banner {
    margin-top: 0;
    padding: 200px 80px 100px 80px;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center;

}

#presentation-column {
    flex: 2;

}

#hire-me-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.hire-me-box {
    background-color: black !important;
    color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    opacity: 0.8;
    align-content: center;
}


.banner .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
}

.banner h1 {
    font-size: 65px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
}

.banner p {
color:white;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.7em;
    width: 96%;
    position: relative;
    transition: color 0.3s ease, text-shadow 0.3s ease;

}

.banner h3 {
    color: white;
    font-size: 30px;
    letter-spacing: 1px;
    line-height: 1.9em;
    width: 96%;
    position: relative;
    transition: color 0.3s ease, text-shadow 0.3s ease;
    text-align: center;
    padding-top: 15px;
}


.banner p:hover,
.banner h3:hover {
    color: aquamarine;
    text-shadow: 0 0 2px teal,
    0 0 10px teal,
    0 0 15px teal,
    0 0 20px aquamarine;

}



.banner button {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    margin-top: 20px;
    letter-spacing: 0.8px;
    display: inline-flex;
    align-items: center;

}

.banner button svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
}

.banner button:hover svg {
    margin-left: 25px;
}

.banner img {
    animation: updown 3s linear infinite;
}

#static-image {
    animation: none;
    opacity: 1;
}


@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}

.txt-rotate > .wrap {
    border-right: 0.08em solid #666;
}

/*#downloadCV-button {*/

/*    color: white;*/
/*    font-size: 18px;*/
/*    letter-spacing: 0.8px;*/
/*    line-height: 1.7em;*/
/*    width: 96%;*/
/*    position: relative;*/
/*    transition: color 0.3s ease, text-shadow 0.3s ease;*/


/*}*/

#downloadCV-button:hover {
    color: lightpink;
    text-shadow: 0 0 20px hotpink,
    0 0 20px darkslateblue,
    0 0 25px white;

}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-overlay.show {
    opacity: 1;
    visibility: visible;
}

.modal-content {
    max-height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease; /* Smooth zoom-in effect */
    transform: scale(0.2);
}

.modal-overlay.show .modal-content {
    transform: scale(1);
}

.modal-image {
    max-width: 80%;
    max-height: 95vh;
    border: 2px solid aqua;
    border-radius: 8px;
    width: auto;
    height: auto;
}

.modal-overlay:hover {
    cursor: pointer;
}

@media (max-width: 768px) {
    .banner {
        padding: 120px 10px;

    }

    .banner h2{
        font-size: 35px;

    }


}

@media (max-width: 480px) {
    .banner {
        padding: 100px 10px;

    }
    .banner h2{
        font-size: 25px;

    }

}