* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
}

body {
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    background-color: #121212 !important;
    background-size: cover;
    background-attachment: fixed;
    color: #fff !important;
    font-family: 'Centra', sans-serif !important;
    background: url('../../../assets/images/starsbg2.jpg') no-repeat center center fixed;
    background-size: cover;

}

.carouselPage {
    display: flex;
    justify-content: right;
    align-items: center;
    min-height: 100vh;
    /*padding: 200px;*/

}

.carousel-page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 80px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;

}

.carousel-container {
    max-width: 1200px;
    margin: 0 auto;

    /*background-color: aqua;*/
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
}

.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    /*background-color: #AA367C;*/
    position: relative;
    width: 100%;


}

.carousel-image {
    height: 100%;
    max-height: 100%;
    width: 170px;
    /*width: 100%;*/
    /*max-width: 1200px;*/
    /*height: auto;*/
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover;
    max-width: 100%;
    cursor: pointer;
}

.carousel-controls {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    pointer-events: none;
}

.carousel-button {

    color: white;

    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    pointer-events: all;

    background-color: rgba(0, 0, 0, 0.7);
    border: 3px solid lightseagreen;
    box-shadow: 0 0 10px teal;

}

.carousel-button:hover {
    transform: scale(1.05);
    background-color: lightpink;
    color: black;
    border: 3px solid lightpink;
    box-shadow: none;
}

.carousel-button:first-child {
    position: absolute;
    left: 10px;
}

.carousel-button:last-child {
    position: absolute;
    right: 10px;
}


.carousel-text-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    align-items: center;
}

.carousel-text {
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 32px 24px 24px 24px;
    font-size: 1.2em;
    line-height: 1.6;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: right;

    border: 3px solid lightseagreen;
    box-shadow: 0 0 10px teal;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    border-radius: 2rem;

}

.carousel-text:hover{
    transform: scale(1.05);
    box-shadow: 0 0 20px aqua;
}

.carousel-text h3{
    text-align: right !important;
    padding-bottom: 24px;
}

.carrousel-back-button {
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: 3px solid lightseagreen;
    box-shadow: 0 0 10px teal;

    position: relative;
    width: 50%;

}

.carrousel-back-button:hover {
    background-color: lightpink;
    color: black;
    border: 3px solid lightpink;
    box-shadow: none;
}


@media (max-width: 768px) {
    .carousel-container {
        padding: 10px;
    }

    .carousel-image {
        width: 100%;
    }

    .carousel-text {
        font-size: 1em;
    }
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-overlay.show {
    opacity: 1;
    visibility: visible;
}

.modal-content {
    max-height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease; /* Smooth zoom-in effect */
    transform: scale(0.2);
}

.modal-overlay.show .modal-content {
    transform: scale(1);
}

.modal-image {
    max-width: 80%;
    max-height: 95vh;
    border: 2px solid aqua;
    border-radius: 8px;
    width: auto;
    height: auto;
}

.modal-overlay:hover {
    cursor: pointer;
}


@media (max-width: 768px) {

    .carouselPage{
        padding: 10px 10px 30px 10px;

    }

    .carousel-page-container{
        padding: 0 10px 30px 10px;
    }

    .carousel-container {
        flex-direction: column;
        padding: 0 10px 30px 10px;
    }
    .carousel-image {
        width: 100%;
        max-height: 300px; /* Limit image height for smaller screens */
    }

    .carousel-text {
        font-size: 0.9em;
        padding: 16px;
    }

    .carousel-text h3 {
        font-size: 1.2em;
    }

    .carousel-button {
        padding: 8px 16px;
        font-size: 0.8em;
    }

    .carrousel-back-button {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .carouselPage{
        padding: 0 10px 30px 10px;

    }
    .carousel-page-container{
        padding: 0 10px 30px 10px;
    }

    .carousel-container {
        flex-direction: column;


    }

    .carousel-text {
        font-size: 0.9em;
        padding: 10px;
    }

    .carousel-button {
        padding: 6px 12px;
        font-size: 0.7em;
    }
}
