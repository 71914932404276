.aboutMe {
    padding: 80px 80px 40px 80px;
    position: relative;

    display: flex;
    flex-direction: column;
    color: white;
}

.aboutMe-bx {
    text-align: right;
    background-color: transparent;
}

.aboutMe h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: left;
    padding-bottom: 40px;
}

.aboutMe-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.aboutMe-col-left, .aboutMe-col-right {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-width: 300px;
    margin: 10px;
}

.text-box {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
      border: 3px solid lightseagreen;
    padding: 20px;
    border-radius: 2rem;
    box-shadow: 0 0 10px teal;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    width: 100%;
    font-size: 1.1em;
    line-height: 1.6;
    text-align: right;
}

.text-box:hover{
    transform: scale(1.02);
    box-shadow: 0 0 20px aqua;
}

.text-box-left {
    align-self: flex-start;
}

.text-box-right {
    align-self: flex-end;
}

.text-box p {
    margin: 0;
    padding: 0;
}

.skills-section {
    text-align: center;
    margin-top: 20px;
    width: 100%;
    max-width: 500px;
}

.skills-section h3 {
    margin-bottom: 20px;
    margin-top: 40px;
}

.soft-skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 12px;
    width: 100%;
    max-width: 800px;
}

.soft-skill-bx {
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: 3px solid lightseagreen;
}

.soft-skill-bx:hover {
    background-color: lightpink;
    color: black;
    border: 3px solid lightpink;
    box-shadow: none;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-overlay.show {
    opacity: 1;
    visibility: visible;
}

.modal-content {
    max-height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease; /* Smooth zoom-in effect */
    transform: scale(0.2);
}

.modal-overlay.show .modal-content {
    transform: scale(1);
}

.modal-image {
    max-width: 80%;
    max-height: 95vh;
    border: 2px solid aqua;
    border-radius: 8px;
    width: auto;
    height: auto;
}

.modal-overlay:hover {
    cursor: pointer;
}


@media (max-width: 768px) {
    .aboutMe {
        padding: 30px 10px 30px 10px;

    }

    .aboutMe h2{
        font-size: 35px;

    }
    .carousel-container {
        padding: 10px;
    }

    .carousel-image {
        width: 100%;
    }

    .carousel-text {
        font-size: 1em;
    }

}

@media (max-width: 480px) {
    .aboutMe {
        padding: 30px 10px 30px 10px;

    }

    .aboutMe h2{
        font-size: 25px;

    }
}
