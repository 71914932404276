.project {
    padding: 80px 80px;
    position: relative;
    background-color: transparent;
     text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.project h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: left;
    padding-bottom: 40px;
}

.project p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%;
}

.project .nav.nav-pills {
    width: 72%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
}

.project .nav.nav-pills .nav-item {
    width: 33.33333%;
}

.project .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, hotpink -5.91%, lightpink 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-first {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-second {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-third {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0 55px 55px 0;
}

.proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

/*.project .row {*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    justify-content: normal;*/
/*    gap: 12px; !* Add gap to control spacing between items *!*/
/*}*/

.proj-imgbx img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.proj-imgbx::before {

    content: "";
    background: rgba(0,0,0, 0.9);
    opacity: 0.85;
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
    height: 100%;
}

.proj-txtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;


}

.proj-imgbx:hover .proj-txtx,
.proj-txtx.show {
    top: 50%;
    opacity: 1;
}

.proj-txtx h4 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
}

.proj-txtx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.8px;
}

.proj-buttons {


    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.proj-imgbx:hover .proj-buttons,
.proj-buttons.show {
    opacity: 1;
}


.proj-buttons button {
    color: white;

    padding: 3px 40px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    pointer-events: all;

    background-color: rgba(0, 0, 0, 0.7);
    border: 3px solid lightseagreen;
    box-shadow: 0 0 10px teal;
}

.proj-buttons button:hover {
    background-color: lightpink;
    color: black;
    border: 3px solid lightpink;
    box-shadow: none;
}

.carousel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.carousel-close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
    z-index: 1001;
}

.carousel-image {
    max-width: 40vw; /* Scale images to fit within viewport width */
    max-height: 40vh; /* Scale images to fit within viewport height */
    object-fit: contain; /* Ensure images are scaled properly without distortion */
}

.background-image-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
}
.tab-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
}

#second-pane{

    width: 100%;
    /*display: flex;*/
    /*align-items: center ;*/
    /*justify-content: center ;*/
    /*height: auto;*/
    /*width: 150%;*/
}



#pending-box{
    display: flex;
    width: auto;
    height: 100px;
    padding: 20px 50px;
    color: #FFFFFF;
    background-color: rgba(0,0,0,0.9);
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    border-radius: 3rem;
}

@media (max-width: 768px) {
    .project {
        padding: 20px 10px;
        justify-content: center;
        align-items: center;
        text-align: center;

    }

    .project h2 {
        font-size: 35px;
        text-align: left;
    }


    .project .nav.nav-pills {
        width: 90%;
        flex-direction: row;
    }

    .project .tab-content{
        padding: 0 150px;

    }

    #pending-box{
        width: 400px;
    }

}


@media (max-width: 480px) {
    .project h2 {
        font-size: 35px;
    }

    .project .nav.nav-pills {
        width: 95%;
        /*margin: 0;*/
        border-radius: 20px;
        background-color: rgb(255 255 255 / 10%);
        overflow: hidden;
    }

    .project .nav.nav-pills .nav-item {
        width: 33.33333%;
    }

    .project .nav.nav-pills .nav-link {
        background-color: transparent;
        border-radius: 0;
        padding: 10px 0;
        color: #fff;
        width: 100%;
        font-size: 14px;
        letter-spacing: 0.8px;
        font-weight: 500;
        position: relative;
        transition: 0.3s ease-in-out;
        text-align: center;
        z-index: 0;
    }

    .project .nav.nav-pills .nav-link::before {
        content: "";
        position: absolute;
        width: 0;
        height: 100%;
        background: linear-gradient(90.21deg, hotpink -5.91%, lightpink 111.58%);
        top: 0;
        left: 0;
        z-index: -1;
        transition: 0.3s ease-in-out;
    }

}