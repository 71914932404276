@font-face {
    font-family: Centra;
    src: url('./assets/fonts/CentraNo2-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: Centra;
    src: url('./assets/fonts/CentraNo2-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: Centra;
    src: url('./assets/fonts/CentraNo2-Book.ttf');
    font-weight: 400;
}

/************ Default Css ************/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
}

body {
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    background-color: #121212 !important;
    color: #fff !important;
    font-family: 'Centra', sans-serif !important;

    background: url('./assets/images/starsbg.jpg') repeat,
    url('./assets/images/starsbg2.jpg') repeat;

}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    line-height: normal;
}

p, a, li, button, ul {
    margin: 0;
    padding: 0;
    line-height: normal;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
}

button {
    border: 0;
    background-color: transparent;
}

input:focus, textarea:focus, select:focus {
    outline: none;
}

@media (min-width: 1700px) {
    main .container {
        max-width: 100%;
        padding: 0 150px;
    }
}

p.success {
    color: green;
}

p.danger {
    color: red;
}

/* Animated BG */

.app {
    transition: background-image 0.5s ease-in-out;
}

.background1 {
    background-image: url('./assets/images/starsbg.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.background2 {
    background-image: url('./assets/images/starsbg2.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

/************ Navbar Css ************/

#my-brand {
    color: floralwhite;
    font-size: 20px;
    font-family: Merriweather, serif;

    padding-right: 16px;
    text-align: left;
    display: flex;
    align-items: center;
    margin-right: 2px;
    font-style: italic;
    font-weight: bold;

}

nav.navbar {
    padding: 24px 0;
    position: fixed;
    width: 100%;
    height: 10%;
    top: 0;
    z-index: 9999;
    transition: 0.32s ease-in-out;
    background-color: transparent;


}

nav.navbar.scrolled {
    padding: 0px 0;
    background-color: black;
    /*#121212;*/
}

nav.navbar a.navbar-brand {
    width: auto;
    /*width: 10%;*/

}

nav.navbar .navbar-nav {
    /* Adjust this value to move items closer to the left */
}

nav.navbar .navbar-nav .nav-link.navbar-link {
    font-weight: 400;
    color: white !important;
    letter-spacing: 0.8px;
    padding: 10px 20px;
    font-size: 18px;
    opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    color: aquamarine !important;
    text-shadow: 0 0 8px teal;
    opacity: 1;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.social-icon {
    /*display: inline-block;*/
    display: flex;
    justify-content: center;
    gap: 2px;
    /*margin-left: 16px;*/
}

.social-icon a {
    width: 44px;
    height: 44px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 10%;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    line-height: 0;

    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;

}

@media (max-width: 991px) {
    .social-icon {
        margin-top: 10px;
    }
}

.social-icon a::before {
    content: "";
    width: 44px;
    height: 44px;
    position: absolute;
    background-color: teal;
    border-radius: 10%;
    transform: scale(0);
    transition: 0.3s ease-in-out;

}

.social-icon a:hover::before {
    transform: scale(1);
    box-shadow: 0 0 8px rgba(64, 224, 208, 0.9);
}


.social-icon a img {
    width: 80%;
    z-index: 1;
    transition: 0.3s ease-in-out;

}

.social-icon a:hover {
    background-color: #327091;
    border: 1px solid #fff;

}

.social-icon a:hover img {
    filter: none;
    /*filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);*/
    box-shadow: 0 0 20px rgba(64, 224, 208, 0.9);

}

#basic-navbar-nav {
    margin-right: 20px;


}

#basic-navbar-nav {
    margin-right: 20px;


}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}


nav.navbar .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid #fff;
    transition: all 300ms linear;
    top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
    border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
    width: 24px;
    position: absolute;
    height: 2px;
    background-color: #fff;
    top: 0;
    left: 0;
    content: '';
    z-index: 2;
    transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
    top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(45deg);
    background-color: #fff;
    height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: translateY(8px) rotate(-45deg);
    background-color: #fff;
    height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
}

/*.navbar-collapse {*/
/*    background-color: black; !* Black background when the menu is toggled *!*/
/*}*/

@media (max-width: 991px) {
    .social-icon {
        margin-top: 10px;
    }

    nav.navbar {
        background-color: black;
    }
}

/************ Lets connect interactive button ************/

.navbar-text button {
    font-weight: 700;
    color: #fff;
    border: 1px solid #fff;
    padding: 18px 34px;
    font-size: 18px;
    margin-left: 18px;
    position: relative;
    background-color: transparent;
    transition: 0.3s ease-in-out;
}

.navbar-text button span {
    z-index: 1;
}

.navbar-text button::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
    color: #121212;
}

.navbar-text button:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
}



/************ StatsBar Css ************/

.stats-bar{
    width: 100%;
    background-color: rgba(0,0,0, 0.8);
}


.stats-bar-container{
    padding: 15px 0;


}

.stats-bar h1{
    font-size: 3.5rem;
    font-weight: bold;
    color: aqua;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

}

.stats-bar p{
    font-size: 1.2rem;
    color: white;

    padding-left: 10px;
    /*width: fit-content;*/
    overflow-wrap: break-word;
    word-wrap: break-word;
    width: 150px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}
